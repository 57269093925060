import { Injectable } from '@angular/core';
import { Constants, MenuConfig, ROUTER_PATHS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class MenuConfigService {
  menuConfig = MenuConfig;
  menuConfigOriginal = JSON.parse(JSON.stringify(MenuConfig));
  menuChild = [
    {
      id: ROUTER_PATHS.dashboard.root,
      title: 'Dashboard',
      icon: Constants.staticImages.icons.dashboard,
      routerLink: ROUTER_PATHS.dashboard.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['dashboard'],
      subMenuOptions: [],
      authorities: [Constants.roles.ALL]
    },
    {
      id: ROUTER_PATHS.schedule.root,
      title: 'Schedule',
      icon: Constants.staticImages.icons.scheduleIcon,
      routerLink: `${ROUTER_PATHS.schedule.root}`,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['schedule'],
      subMenuOptions: [],
      authorities: [
        Constants.roles.ADMIN,
        Constants.roles.DESK_MANAGER,
        Constants.roles.INSTRUCTOR,
        Constants.roles.SUPERVISOR
      ]
    },
    {
      id: ROUTER_PATHS.roomAndLocation.root,
      title: 'Room & Location Management',
      icon: Constants.staticImages.icons.roomIcon,
      routerLink: ROUTER_PATHS.roomAndLocation.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['Room & Location Management'],
      subMenuOptions: [],
      authorities: [Constants.roles.ADMIN]
    },
    {
      id: ROUTER_PATHS.attendance,
      title: 'Attendance',
      icon: Constants.staticImages.icons.checkCircleWhite,
      routerLink: ROUTER_PATHS.attendance,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['Attendance'],
      subMenuOptions: [],
      authorities: [Constants.roles.ADMIN, Constants.roles.INSTRUCTOR, Constants.roles.SUPERVISOR]
    },
    {
      id: ROUTER_PATHS.clientInquiry,
      title: 'Client Inquiry',
      icon: Constants.staticImages.icons.studentEnquiryIcon,
      routerLink: ROUTER_PATHS.clientInquiry,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['Client Inquiry'],
      subMenuOptions: [],
      authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
    },
    {
      id: ROUTER_PATHS.members.root,
      title: 'Members',
      icon: Constants.staticImages.icons.memberIcon,
      routerLink: ROUTER_PATHS.members.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['members'],
      authorities: [
        Constants.roles.ADMIN,
        Constants.roles.DESK_MANAGER,
        Constants.roles.INSTRUCTOR,
        Constants.roles.SUPERVISOR
      ],
      subMenuOptions: [
        {
          id: ROUTER_PATHS.members.clients,
          title: 'Clients',
          icon: '',
          routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.clients}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.members.root,
          authorities: [
            Constants.roles.ADMIN,
            Constants.roles.DESK_MANAGER,
            Constants.roles.INSTRUCTOR,
            Constants.roles.SUPERVISOR
          ]
        },
        {
          id: ROUTER_PATHS.members.instructors,
          title: 'Instructors',
          icon: '',
          routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.instructors}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.members.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER, Constants.roles.SUPERVISOR]
        },
        {
          id: ROUTER_PATHS.members.supervisors,
          title: 'Supervisors',
          icon: '',
          routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.supervisors}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.members.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        },
        {
          id: ROUTER_PATHS.members.deskManagers,
          title: 'Desk Managers',
          icon: '',
          routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.deskManagers}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.members.root,
          authorities: [Constants.roles.ADMIN]
        }
      ]
    },
    {
      id: ROUTER_PATHS.request.root,
      title: 'Request',
      icon: Constants.staticImages.icons.whiteFilesFolders,
      routerLink: ROUTER_PATHS.request.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['request'],
      authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER, Constants.roles.SUPERVISOR, Constants.roles.INSTRUCTOR],
      subMenuOptions: [
        {
          id: ROUTER_PATHS.request.planCancelRequest,
          title: 'Plan Cancel Request',
          icon: '',
          routerLink: `${ROUTER_PATHS.request.root}/${ROUTER_PATHS.request.planCancelRequest}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.request.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        },
        {
          id: ROUTER_PATHS.request.leaveRequest,
          title: 'Leave Request',
          icon: '',
          routerLink: `${ROUTER_PATHS.request.root}/${ROUTER_PATHS.request.leaveRequest}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.request.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER, Constants.roles.SUPERVISOR, Constants.roles.INSTRUCTOR]
        }
      ]
    },
    {
      id: ROUTER_PATHS.visits.root,
      title: 'Visits/Scheduling',
      icon: Constants.staticImages.icons.visitScheduling,
      routerLink: ROUTER_PATHS.visits.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['visits'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.plansAndPasses.root,
      title: 'Plans & Passes',
      icon: Constants.staticImages.icons.fileCheck,
      routerLink: ROUTER_PATHS.plansAndPasses.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['plans-and-passes'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.scheduleClasses.root,
      title: 'Schedule Classes',
      icon: Constants.staticImages.icons.scheduleIcon,
      routerLink: ROUTER_PATHS.scheduleClasses.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['schedule-classes'],
      authorities: [Constants.roles.USER],
      subMenuOptions: [
        {
          id: ROUTER_PATHS.scheduleClasses.introductoryLesson,
          title: 'Introductory Lesson',
          icon: '',
          routerLink: `${ROUTER_PATHS.scheduleClasses.root}/${ROUTER_PATHS.scheduleClasses.introductoryLesson}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.scheduleClasses.root,
          authorities: [Constants.roles.USER]
        },
        {
          id: ROUTER_PATHS.scheduleClasses.groupClasses,
          title: 'Group Classes',
          icon: '',
          routerLink: `${ROUTER_PATHS.scheduleClasses.root}/${ROUTER_PATHS.scheduleClasses.groupClasses}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.scheduleClasses.root,
          authorities: [Constants.roles.USER]
        },
        {
          id: ROUTER_PATHS.scheduleClasses.summerCamp,
          title: 'Summer Camp',
          icon: '',
          routerLink: `${ROUTER_PATHS.scheduleClasses.root}/${ROUTER_PATHS.scheduleClasses.summerCamp}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.scheduleClasses.root,
          authorities: [Constants.roles.USER]
        }
      ]
    },
    {
      id: ROUTER_PATHS.document.root,
      title: 'Document',
      icon: Constants.staticImages.icons.documentIcon,
      routerLink: ROUTER_PATHS.document.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['document'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.referral.root,
      title: 'Referral',
      icon: Constants.staticImages.icons.referral,
      routerLink: ROUTER_PATHS.referral.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['referral'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.help.root,
      title: 'Help',
      icon: Constants.staticImages.icons.help,
      routerLink: ROUTER_PATHS.help.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['help'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.settings.root,
      title: 'Settings',
      icon: Constants.staticImages.icons.gearWhite,
      routerLink: ROUTER_PATHS.settings.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['settings'],
      authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER],
      subMenuOptions: [
        {
          id: ROUTER_PATHS.settings.plan,
          title: 'Plan',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.plan}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        },
        {
          id: ROUTER_PATHS.settings.passes,
          title: 'Passes',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.passes}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        },
        {
          id: ROUTER_PATHS.settings.groupClass,
          title: 'Group Class',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.groupClass}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        },
        {
          id: ROUTER_PATHS.settings.summerCamp,
          title: 'Summer Camp',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.summerCamp}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        },
        {
          id: ROUTER_PATHS.settings.revenueCategory,
          title: 'Revenue Category',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.revenueCategory}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        },
        {
          id: ROUTER_PATHS.settings.document,
          title: 'Document',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.document}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        },
        {
          id: ROUTER_PATHS.settings.referral,
          title: 'Referral',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.referral}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        },
        {
          id: ROUTER_PATHS.settings.help,
          title: 'Help',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.help}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        }
      ]
    }
  ];

  constructor() {}

  modifyMenuConfig(menuConfig: Array<any>, role: string): void {
    for (const [index, menu] of menuConfig.entries()) {
      const selectedMenu = this.menuChild.find((menuItem) => menu.title === menuItem.title);
      menuConfig[index].subMenuOptions = selectedMenu?.subMenuOptions as any;
    }

    const userPermissions = [role, Constants.roles.ALL];
    this.menuConfig = this.filterMenuByPermissions(this.menuConfig, userPermissions);
  }

  filterMenuByPermissions(menuOptions: any[], userPermissions: string[]): any[] {
    return menuOptions.filter((menu) => {
      // Check if the menu is accessible by the user's role
      const hasPermission = menu.authorities.some((role: string) => userPermissions.includes(role));

      if (!hasPermission) {
        return false; // If the user doesn't have permission, exclude the menu item.
      }

      // If there are sub-menu options, filter them based on user permissions.
      if (menu.subMenuOptions && menu.subMenuOptions.length) {
        menu.subMenuOptions = menu.subMenuOptions.filter((subMenu: any) =>
          subMenu.authorities.some((role: string) => userPermissions.includes(role))
        );
      }

      return true; // Include the menu if the user has the required permissions.
    });
  }

  getMenuConfig() {
    return this.menuConfig;
  }

  resetMenuConfig(): void {
    this.menuConfig = this.menuConfigOriginal;
  }
}
