import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedLayoutComponent } from './layout/authorized-layout/authorized-layout.component';
import { UnauthorizedLayoutComponent } from './layout/unauthorized-layout/unauthorized-layout.component';
import { Constants, ROUTER_PATHS } from './shared/constants';
import { AuthGuard, NoAuthGuard } from './shared/guards';
import { RoleGuard } from './shared/guards/role.guard';

const routes: Routes = [
  {
    path: ROUTER_PATHS.auth.root,
    component: UnauthorizedLayoutComponent,
    loadChildren: async () => (await import('./auth/auth.module')).AuthModule
  },
  {
    path: ROUTER_PATHS.requestInformation,
    loadComponent: async () =>
      (await import('./request-information/pages/request-information.component')).RequestInformationComponent,
    canMatch: [() => inject(NoAuthGuard).canMatch()],
    data: {
      pageTitle: 'Request Information'
    }
  },
  {
    path: ROUTER_PATHS.scheduleIntroductoryLesson,
    loadComponent: async () =>
      (await import('./schedule-introductory-lesson/schedule-introductory-lesson.component'))
        .ScheduleIntroductoryLessonComponent,
    canMatch: [() => inject(NoAuthGuard).canMatch()],
    data: {
      pageTitle: 'Schedule Introductory Lesson'
    }
  },
  {
    path: '',
    component: AuthorizedLayoutComponent,
    canMatch: [() => inject(AuthGuard).canMatch()],
    children: [
      {
        path: ROUTER_PATHS.dashboard.root,
        loadChildren: async () => (await import('./pages/dashboard/dashboard.module')).DashboardModule
      },
      {
        path: ROUTER_PATHS.profile.root,
        loadChildren: async () => (await import('./pages/profile/profile.module')).ProfileModule
      },
      {
        path: ROUTER_PATHS.visits.root,
        loadComponent: async () =>
          (await import('./pages/visits-scheduling/visits-scheduling.component')).VisitsSchedulingComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Visits/Scheduling',
          roles: [Constants.roles.USER]
        }
      },
      {
        path: `${ROUTER_PATHS.visits.root}/${ROUTER_PATHS.scheduleMakeUpLesson}`,
        loadComponent: async () =>
          (await import('./pages/plans-and-passes/pages/schedule-make-up-lesson/schedule-make-up-lesson.component'))
            .ScheduleMakeUpLessonComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Schedule Make Up Lesson',
          roles: [Constants.roles.USER]
        }
      },
      {
        path: ROUTER_PATHS.plansAndPasses.root,
        loadComponent: async () =>
          (await import('./pages/plans-and-passes/plans-and-passes.component')).PlansAndPassesComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Plans and Passes',
          roles: [Constants.roles.USER]
        }
      },
      {
        path: `${ROUTER_PATHS.plansAndPasses.root}/${ROUTER_PATHS.scheduleMakeUpLesson}`,
        loadComponent: async () =>
          (await import('./pages/plans-and-passes/pages/schedule-make-up-lesson/schedule-make-up-lesson.component'))
            .ScheduleMakeUpLessonComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Schedule Make Up Lesson',
          roles: [Constants.roles.USER]
        }
      },
      {
        path: ROUTER_PATHS.referral.root,
        loadComponent: async () =>
          (await import('./pages/referral-page/referral-page.component')).ReferralPageComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Referral',
          roles: [Constants.roles.USER]
        }
      },
      {
        path: ROUTER_PATHS.document.root,
        loadComponent: async () =>
          (await import('./pages/user-document/user-document.component')).UserDocumentComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Document',
          roles: [Constants.roles.USER]
        }
      },
      {
        path: ROUTER_PATHS.help.root,
        loadChildren: async () => (await import('./pages/help/help.module')).HelpModule,
        canActivate: [RoleGuard],
        data: {
          roles: [Constants.roles.USER]
        }
      },
      {
        path: `${ROUTER_PATHS.roomAndLocation.root}`,
        loadComponent: async () =>
          (await import('./pages/room-and-location-management/room-and-location-management.component'))
            .RoomAndLocationManagementComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Room and Location',
          roles: [Constants.roles.ADMIN]
        }
      },
      {
        path: ROUTER_PATHS.schedule.root,
        loadChildren: async () => (await import('./pages/scheduler/scheduler.module')).SchedulerModule,
        canActivate: [RoleGuard],
        data: {
          roles: [
            Constants.roles.ADMIN,
            Constants.roles.DESK_MANAGER,
            Constants.roles.INSTRUCTOR,
            Constants.roles.SUPERVISOR
          ]
        }
      },
      {
        path: ROUTER_PATHS.attendance,
        loadComponent: async () => (await import('./pages/attendance/attendance.component')).AttendanceComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Attendance',
          roles: [Constants.roles.ADMIN, Constants.roles.INSTRUCTOR, Constants.roles.SUPERVISOR]
        }
      },
      {
        path: ROUTER_PATHS.clientInquiry,
        loadComponent: async () =>
          (await import('./pages/student-inquiry/student-inquiry.component')).StudentInquiryComponent,
        canActivate: [RoleGuard],
        data: {
          pageTitle: 'Student Inquiry',
          roles: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        }
      },
      {
        path: ROUTER_PATHS.members.root,
        loadChildren: async () => (await import('./pages/members/members.module')).MembersModule,
        canActivate: [RoleGuard],
        data: {
          roles: [
            Constants.roles.ADMIN,
            Constants.roles.DESK_MANAGER,
            Constants.roles.INSTRUCTOR,
            Constants.roles.SUPERVISOR
          ]
        }
      },
      {
        path: ROUTER_PATHS.request.root,
        loadChildren: async () => (await import('./pages/requests/requests.module')).RequestsModule,
        canActivate: [RoleGuard],
        data: {
          roles: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER, Constants.roles.SUPERVISOR, Constants.roles.INSTRUCTOR]
        }
      },
      {
        path: ROUTER_PATHS.settings.root,
        loadChildren: async () => (await import('./pages/settings/settings.module')).SettingsModule,
        canActivate: [RoleGuard],
        data: {
          roles: [Constants.roles.ADMIN, Constants.roles.DESK_MANAGER]
        }
      },
      {
        path: ROUTER_PATHS.scheduleClasses.root,
        loadChildren: async () =>
          (await import('./pages/schedule-classes/schedule-classes.module')).ScheduleClassesModule,
        canActivate: [RoleGuard],
        data: {
          roles: [Constants.roles.USER]
        }
      },
      {
        path: `${ROUTER_PATHS.error.notFound}`,
        pathMatch: 'full',
        loadComponent: async () =>
          (await import('./pages/page-not-found/page-not-found.component')).PageNotFoundComponent
      },
      { path: '', redirectTo: ROUTER_PATHS.dashboard.root, pathMatch: 'full' },
      {
        path: `**`,
        redirectTo: `${ROUTER_PATHS.error.notFound}`
      }
    ]
  },
  {
    path: 'pages/requests',
    loadChildren: () => import('./pages/requests/requests.module').then((m) => m.RequestsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
